.home {
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans KR', sans-serif;
}

.border {
  border: 1px solid rgb(134, 134, 134);
  width: 94%;
  height: 94%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
}

#selected-user {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

#select-user {
  flex: 1.5;
}

h1, h3 {
  margin: 0;
  font-weight: 200;
}

h1 {
  font-size: 5.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  color: white;
}

#business-day-num, #nickname {
  font-weight: 600;
}

#user-table {
  max-height: 500px;
  overflow-y: scroll;
}

